import axios from "axios";

export default {
    namespaced: true,
    state: {
        inclusions: [],
    },
    getters: {
        inclusions(state) {
            return state.inclusions;
        },
    },
    mutations: {
        SET_INCLUSIONS(state, value) {
            state.inclusions = value;
        },
    },
    actions: {
        async inclusions({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/inclusion",
                    credentials
                );
                commit("SET_INCLUSIONS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("api/auth/inclusion/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("api/auth/inclusion/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async update(_, credentials) {
            console.log(credentials);
            try {
                await axios.post("api/auth/inclusion/update", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

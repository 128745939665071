import axios from "axios";

export default {
    namespaced: true,
    state: {
        booking: [],
    },
    getters: {
        booking(state) {
            return state.booking;
        },
    },
    mutations: {
        SET_BOOKING(state, value) {
            state.booking = value;
        },
    },
    actions: {
        async booking({ commit }, credentials) {
            try {
                let res = await axios.get("/guide/booking", {
                    params: credentials,
                });

                commit("SET_BOOKING", res.data.data);
            } catch (e) {
                console.log(e);
            }
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        authenticated: false,
        user: [],
        snackbar: false,
        error: "",
        color: "",
    },
    getters: {
        check(state) {
            return state.authenticated;
        },
        user(state) {
            return state.user;
        },
        snackbar(state) {
            return state.snackbar;
        },
        error(state) {
            return state.error;
        },
        color(state) {
            return state.color;
        },
    },
    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        },
        SET_USER(state, value) {
            state.user = value;
        },
        SET_SNACKBAR(state) {
            state.snackbar = true;
            setTimeout(() => {
                state.snackbar = false;
            }, 5000);
        },
        SET_ERROR(state, value) {
            state.error = value.msg;
            state.color = value.color;
        },
    },
    actions: {
        async login({ commit }, credentials) {
            await axios.get("/sanctum/csrf-cookie");
            await axios.post("/login", credentials);
            let response = await axios.get("/api/user");

            // dispatch("me");
            commit("SET_AUTHENTICATED", true);
            commit("SET_USER", response.data);
        },

        async me({ commit }) {
            try {
                let response = await axios.get("/api/user");
                commit("SET_AUTHENTICATED", true);
                commit("SET_USER", response.data);
            } catch (e) {
                commit("SET_AUTHENTICATED", false);
                commit("SET_USER", []);
            }
        },

        async logout({ commit }) {
            await axios.post("/logout");
            commit("SET_AUTHENTICATED", false);
            commit("SET_USER", []);
        },

        snackbar({ commit }, credentials) {
            commit("SET_ERROR", credentials);
            commit("SET_SNACKBAR");
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        avail: [],
    },
    getters: {
        avail(state) {
            return state.avail;
        },
    },
    mutations: {
        SET_AVAIL(state, value) {
            state.avail = value;
        },
    },
    actions: {
        async availabilities({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/availabilities",
                    credentials
                );
                // console.log(response.data.data);
                commit("SET_AVAIL", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async addDayOff(_, credentials) {
            try {
                await axios.post("/api/auth/availability/off/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delDayOff(_, credentials) {
            try {
                await axios.post("/api/auth/availability/off/del", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        bookings: [],
        total: 0,
        error: false,
        booking: "",
        bills: [],
    },
    getters: {
        bookings(state) {
            return state.bookings;
        },
        total(state) {
            return state.total;
        },
        error(state) {
            return state.error;
        },
        booking(state) {
            return state.booking;
        },
        bills(state) {
            return state.bills;
        },
    },
    mutations: {
        SET_BOOKINGS(state, value) {
            state.bookings = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_BOOKING(state, value) {
            state.booking = value;
        },
        SET_BILLS(state, value) {
            state.bills = value;
        },
    },
    actions: {
        async bookingsList({ commit }, credentials) {
            try {
                let response = await axios.post("/api/auth/bills", credentials);
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_BOOKINGS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async bills({ commit }, credentials) {
            try {
                let response = await axios.post("/api/auth/bills", credentials);
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_BILLS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async approve(_, credentials) {
            try {
                await axios.post("/api/auth/trx/approve/tour", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async detail({ commit }, credentials) {
            try {
                let res = await axios.post(
                    "/api/auth/bill/detail",
                    credentials
                );
                commit("SET_BOOKING", res.data.data.booking);
                commit("SET_BILLS", res.data.data.bills);
            } catch (err) {
                console.log(err);
            }
        },

        async filter({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/bill/filter",
                    credentials
                );
                console.log(response.data.data);
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_BOOKINGS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("/api/auth/bill/add", credentials, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            } catch (e) {
                console.log(e);
            }
        },
        async delete(_, credentials) {
            try {
                await axios.post("/api/auth/bill/delete", credentials);
            } catch (e) {
                console.log(e);
            }
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        options: [],
        total: 0,
        option: "",
    },
    getters: {
        options(state) {
            return state.options;
        },
        total(state) {
            return state.total;
        },
        option(state) {
            return state.option;
        },
    },
    mutations: {
        SET_OPTIONS(state, value) {
            state.options = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_OPTION(state, value) {
            state.option = value;
        },
    },
    actions: {
        async optionsList({ commit }, credentials) {
            console.log("options");
            try {
                let response = await axios.post("/api/auth/tours", credentials);
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_OPTIONS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("api/auth/tour/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("api/auth/tour/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async update(_, credentials) {
            try {
                await axios.post("api/auth/tour/update", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async find({ commit }, credentials) {
            try {
                let res = await axios.post("api/auth/tour/find", credentials);
                commit("SET_OPTION", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

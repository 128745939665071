import axios from "axios";

export default {
    namespaced: true,
    state: {
        tours: [],
        total: 0,
        tour: "",
        relations: [],
    },
    getters: {
        tours(state) {
            return state.tours;
        },
        total(state) {
            return state.total;
        },
        tour(state) {
            return state.tour;
        },
        relations(state) {
            return state.relations;
        },
    },
    mutations: {
        SET_TOURS(state, value) {
            state.tours = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_TOUR(state, value) {
            state.tour = value;
        },
        SET_RELATIONS(state, value) {
            state.relations = value;
        },
    },
    actions: {
        async toursList({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/packages",
                    credentials
                );
                console.log(response.data.data);
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_TOURS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add({ commit }, credentials) {
            try {
                let res = await axios.post("api/auth/package/add", credentials);
                commit("SET_TOUR", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("api/auth/package/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async update(_, credentials) {
            try {
                await axios.post("api/auth/package/update", credentials, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            } catch (err) {
                console.log(err);
            }
        },

        async find({ commit }, credentials) {
            try {
                let res = await axios.post(
                    "api/auth/package/find",
                    credentials
                );
                commit("SET_TOUR", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async relations({ commit }, credentials) {
            try {
                let res = await axios.post(
                    "api/auth/package/relations",
                    credentials
                );
                commit("SET_RELATIONS", res.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async relationADD(_, credentials) {
            try {
                await axios.post("api/auth/package/relation/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },
        async relationDEL(_, credentials) {
            try {
                await axios.post(
                    "api/auth/package/relation/delete",
                    credentials
                );
            } catch (err) {
                console.log(err);
            }
        },
    },
};

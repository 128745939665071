import axios from "axios";

export default {
    namespaced: true,
    state: {
        times: [],
    },
    getters: {
        times(state) {
            return state.times;
        },
    },
    mutations: {
        SET_TIMES(state, value) {
            state.times = value;
        },
    },
    actions: {
        async times({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/times/by/tour",
                    credentials
                );
                // console.log(response.data.data);
                commit("SET_TIMES", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("/api/auth/time/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("/api/auth/time/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        counts: [],
    },
    getters: {
        counts(state) {
            return state.counts;
        },
    },
    mutations: {
        SET_COUNT(state, value) {
            state.counts = value;
        },
    },
    actions: {
        async counts({ commit }) {
            try {
                let res = await axios.post("/api/auth/count");
                commit("SET_COUNT", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        aprices: [],
        cprices: [],
    },
    getters: {
        aprices(state) {
            return state.aprices;
        },
        cprices(state) {
            return state.cprices;
        },
    },
    mutations: {
        SET_APRICES(state, value) {
            state.aprices = value;
        },
        SET_CPRICES(state, value) {
            state.cprices = value;
        },
    },
    actions: {
        async aprices({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/prices",
                    credentials
                );
                // console.log(response.data.data);
                commit("SET_APRICES", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async cprices({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/prices",
                    credentials
                );
                // console.log(response.data.data);
                commit("SET_CPRICES", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("/api/auth/price/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("/api/auth/price/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        exclusions: [],
    },
    getters: {
        exclusions(state) {
            return state.exclusions;
        },
    },
    mutations: {
        SET_EXCLUSIONS(state, value) {
            state.exclusions = value;
        },
    },
    actions: {
        async exclusions({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/exclusion",
                    credentials
                );
                commit("SET_EXCLUSIONS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("api/auth/exclusion/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("api/auth/exclusion/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async update(_, credentials) {
            console.log(credentials);
            try {
                await axios.post("api/auth/exclusion/update", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

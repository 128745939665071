import axios from "axios";

export default {
    namespaced: true,
    state: {
        users: [],
        total: 0,
        user: "",
    },
    getters: {
        users(state) {
            return state.users;
        },
        total(state) {
            return state.total;
        },
        user(state) {
            return state.user;
        },
    },
    mutations: {
        SET_USERS(state, value) {
            state.users = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_USER(state, value) {
            state.user = value;
        },
    },
    actions: {
        async users({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/users",
                    credentials
                );
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_USERS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("/api/auth/user/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("/api/auth/user/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async find({ commit }, credentials) {
            try {
                let res = await axios.post("/api/auth/user/find", credentials);
                commit("SET_USER", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async update(_, credentials) {
            try {
                await axios.post("/api/auth/user/update", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

import axios from "axios";

export default {
    namespaced: true,
    state: {
        guides: [],
        guidesReady: [],
        total: 0,
        totalReady: 0,
        guide: "",
        month: 0,
    },
    getters: {
        guides(state) {
            return state.guides;
        },
        guidesReady(state) {
            return state.guidesReady;
        },
        total(state) {
            return state.total;
        },
        totalReady(state) {
            return state.totalReady;
        },
        guide(state) {
            return state.guide;
        },
        month(state) {
            return state.month;
        },
    },
    mutations: {
        SET_GUIDES(state, value) {
            state.guides = value;
        },
        SET_GUIDES_READY(state, value) {
            state.guidesReady = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_TOTAL_READY(state, value) {
            state.totalReady = value;
        },
        SET_GUIDE(state, value) {
            state.guide = value;
        },
        SET_MONTH(state, value) {
            state.month = value;
        },
    },
    actions: {
        changeMonth({ commit }, credentials) {
            commit("SET_MONTH", credentials.month);
        },
        async guidesList({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/guides",
                    credentials
                );
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_GUIDES", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async guidesReady({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/guides/availability",
                    credentials
                );
                commit("SET_TOTAL_READY", response.data.total);
                commit("SET_GUIDES_READY", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("/api/auth/guide/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("/api/auth/guide/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async find({ commit }, credentials) {
            try {
                let res = await axios.post("/api/auth/guide/find", credentials);
                commit("SET_GUIDE", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async update(_, credentials) {
            try {
                await axios.post("/api/auth/guide/update", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};

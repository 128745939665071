import axios from "axios";

export default {
    namespaced: true,
    state: {
        bookings: [],
        total: 0,
        error: false,
        packages: [],
        options: [],
        booking: "",
        multidays: [],
    },
    getters: {
        bookings(state) {
            return state.bookings;
        },
        total(state) {
            return state.total;
        },
        error(state) {
            return state.error;
        },
        booking(state) {
            return state.booking;
        },
        packages(state) {
            return state.packages;
        },
        options(state) {
            return state.options;
        },
        multidays(state) {
            return state.multidays;
        },
    },
    mutations: {
        SET_BOOKINGS(state, value) {
            state.bookings = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_BOOKING(state, value) {
            state.booking = value;
        },
        SET_PACKAGES(state, value) {
            state.packages = value;
        },
        SET_OPTIONS(state, value) {
            state.options = value;
        },
        SET_MULTIDAYS(state, value) {
            state.multidays = value;
        },
    },
    actions: {
        async bookingsList({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/bookings",
                    credentials
                );

                commit("SET_TOTAL", response.data.data.total);
                commit("SET_BOOKINGS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async findDate({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/booking/find/date",
                    credentials
                );
                commit("SET_BOOKINGS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async findPackages({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/packages",
                    credentials
                );
                commit("SET_PACKAGES", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async findOptions({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/booking/options",
                    credentials
                );
                commit("SET_OPTIONS", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async findByRefID({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/booking/find/ref",
                    credentials
                );
                commit("SET_BOOKINGS", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add({ dispatch, commit }, credentials) {
            try {
                let res = await axios.post(
                    "/api/auth/booking/add",
                    credentials
                );

                commit("SET_ERROR", false);
                dispatch(
                    "auth/snackbar",
                    {
                        msg: res.data.meta.message,
                        color: "green",
                    },
                    { root: true }
                );
            } catch (err) {
                commit("SET_ERROR", true);
                dispatch(
                    "auth/snackbar",
                    {
                        msg: "Date and name are required, Ref ID can't be the same",
                        color: "red",
                    },
                    { root: true }
                );
            }
        },

        async detail({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/booking/find",
                    credentials
                );
                commit("SET_BOOKING", response.data.data.data);
                commit("SET_MULTIDAYS", response.data.data.multidays);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("/api/auth/booking/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async sendNotification(_, credentials) {
            try {
                await axios.post("/api/auth/send/select/guide", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async booking({ commit }, credentials) {
            try {
                let res = await axios.post(
                    "/api/auth/booking/find",
                    credentials
                );
                commit("SET_BOOKING", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async update({ dispatch }, credentials) {
            try {
                let res = await axios.post(
                    "/api/auth/booking/update",
                    credentials
                );
                dispatch(
                    "auth/snackbar",
                    {
                        msg: res.data.meta.message,
                        color: "green",
                    },
                    { root: true }
                );
            } catch (err) {
                dispatch(
                    "auth/snackbar",
                    {
                        msg: "Date and name are required, Ref ID can't be the same",
                        color: "red",
                    },
                    { root: true }
                );
            }
        },

        async guideUpdate(_, credentials) {
            try {
                await axios.post("/api/auth/booking/update/guide", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async approve(_, credentials) {
            try {
                await axios.post("/api/auth/trx/approve/tour", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async filter({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/booking/filter",
                    credentials
                );
                // console.log("DATA : ", response.data.data);
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_BOOKINGS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async status(_, credentials) {
            try {
                await axios.post(
                    "/api/auth/booking/update/status",
                    credentials
                );
            } catch (err) {
                console.log(err);
            }
        },
    },
};
